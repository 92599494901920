import React from "react";
import clc from "../imgs/clients.png";
import { useEffect } from "react";
export default function ClientsAR() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div class="boxC">
        <p className="pagetitleAR">عملائنا</p>
      </div>
      <h1 className="clientssloganAR">ثقة ورضـى عملائنا هدفنا الاول</h1>
      <p className="clientspAR"> نهل القابضة تملك العديد من الشركات ذات قصص نجاح رائعة<br/> .بالتعاون مع عملائنا الذين تسعدنا ثقتهم بنا</p>
      
      <img className="clc" src={clc} alt="" />
    </div>
  );
}
