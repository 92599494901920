import React from "react";
import "./styles/App.css";
import Navbar from "./components/Navbar";
import {Route, Routes, useLocation} from "react-router-dom";
import Contacts from "./components/contacts";
import Clients from "./components/clients";
import Footer from "./components/footer";
import Home from "./components/home";
import Companies from "./components/companies";
import HomeAR from "./ar/homeAR";
import ContactsAR from "./ar/contactsAR";
import NavbarAR from "./ar/NavbarAR";
import CompaniesAR from "./ar/companiesAR";
import ClientsAR from "./ar/clientsAR";
import FooterAR from "./ar/footerAR"

function App() {


let location = useLocation();

  return (
    <div className="App">
      <div className="content">
       
           
           {location.pathname.includes("/ar") ? <NavbarAR/> : <Navbar/>} 

          <Routes>
            
            <Route path="/" element={<Home />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/contact-us" element={<Contacts />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/ar" element={<HomeAR />} />
            <Route path="/ar/clients" element={<ClientsAR />} />
            <Route path="/ar/companies" element={<CompaniesAR/>} />
            <Route path="/ar/contact-us" element={<ContactsAR />} />
          </Routes>
        
      </div>

      
      {location.pathname.includes("/ar") ? <FooterAR />: <Footer />} 
    </div>
  );
}

export default App;


