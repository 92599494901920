import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../imgs/logocolored.png";
import logoW from "../imgs/logocoloredW.png";
export default function Navbar() {
  const [active, setActive] = useState("nav_menu");
  const [toggleIcon, setToggleIcon] = useState("nav_toggler");
  const navToggle = () => {
    active === "nav_menu"
      ? setActive("nav_menu nav_active")
      : setActive("nav_menu");
    toggleIcon === "nav_toggler"
      ? setToggleIcon("nav_toggler toggle")
      : setToggleIcon("nav_toggler");
  };

  const [nav, setNav] = useState(false);
  const cb = () => {
  
    if (window.scrollY >= 300) {
      setNav(true);

    } else {
      setNav(false);
        }
  };

  window.addEventListener('scroll', cb);
  return (
    <nav className={nav ? 'nav active ' : 'nav'}>
      <Link to="/" className="site-title">
        <img className="navlogo" src={nav ? (logo) : (logoW)} alt="s" />
      </Link>
      <ul className={active}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/companies">Companies</Link>
        </li>
        <li>
          <Link to="/clients">Clients</Link>
        </li>
        <li>
          <Link to="/contact-us">Contact Us</Link>
        </li>
        <li className="navAR">
        <Link to="/ar">عربي</Link>
        </li>
      </ul>
      <div onClick={navToggle} className={toggleIcon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}
