import React from "react";

export default function Footer() {
  return (
    <div className="main_footer">
      <div className="container-footer">
        <div className="row"></div>
        <div className="call">
          <a href="tel:+966112226999" className="callBtn">
          Give Us a Call!
          </a>
        </div>
        <hr />
        <div className="copyrights">
          &copy;{new Date().getFullYear()} - All Rights Reserved. Designed and
          Developed by NAHL holding
        </div>
      </div>
    </div>
  );
}
