import React from "react";
import p from "../imgs/planning.svg";
import v from "../imgs/vision.svg";
import q from "../imgs/quality.svg";
import m from "../imgs/manage.svg";
import d from "../imgs/diversify.svg";
import l from "../imgs/nahl white-02.png";
import Item from "./Item";
import Carousel from 'react-elastic-carousel';
import img1 from "../imgs/nahl group-01.png";
import img2 from "../imgs/nahl group-02.png";
import img3 from "../imgs/nahl group-03.png";
import img4 from "../imgs/nahl group-04.png";
import img5 from "../imgs/nahl group-05.png";
import img7 from "../imgs/nahl group-07.png";

export default function Home() {

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
  ];

  return (
    <div>
      <div className="box2">
        <img className="logoindex" src={l} alt="logo"/>
        <p className="pp">
          <h1 className="titleWord">NAHL Holding</h1>
          NAHL is a Saudi holding company that holds diversified companies{" "}
          <br />
          in multiple fields, focused on the future businesses. <br /> <br /> <br /> <br /> <br />
        </p>
        <h3 className="slogan">
          "Innovation and uniqueness are our drivers"
        </h3>
      </div>
      <div className="box3">
        <div>
          <p className="pvision">
            <img src={v} class="visionico" alt="" />
            <h1 className="visiontitle">NAHL Vision</h1>
            is to be recognized globally as a company who add real values <br />
            to our nation and the next generation, participating in making our
            future better, <br />
            through studied moves after deep analysis and planning by our
            professional team.
            <br />
            <br />
            <br />
            <br />
          </p>
        </div>
        <div className="visionsGroup">
          <p className="visions">
            <img src={q} class="qualityicon" alt="" />
            <span className="visionstitle">Quality assurance</span>
            By reviewing all the processes & procedures,
            <br />
            gathering the clients feedback,
            <br />
            following the quality assurance best practices,
            <br />
            we guarantee the quality of our companies
          </p>
          <p className="visions">
            <img src={p} class="visionicon" alt="" />
            <span className="visionstitle">Planning</span>
            Well organized short & long run plans
            <br />
            to reduce the risks and assure the highest optimization
            <br />
            for the companies, converting the circumstance into a benefit
          </p>
          <p className="visions">
            <img src={m} class="visionicon" alt="" />
            <span className="visionstitle">Manage & Control</span>
            Through our qualified team: NAHL is providing the management
            services
            <br />
            for the subsidiaries: Finance, HR, logistic & administration
          </p>
          <p className="visions">
            <img src={d} class="visionicon" alt="" />
            <span className="visionstitle">Diversify</span>
            Increasing the holding balance by having new investment
            <br />
            opportunities either by establishing new services, new companies,
            <br />
            or acquiring running ones. Our consulting team is always ready
          </p>
        </div>
      </div>
      <div className="box4">
        <p className="com">Our Companies</p>
        <div className="carousel-wrapper">
        <Carousel className="item" breakPoints={breakPoints}>
        <img src={img4} alt="tech stations company"/>
        <img src={img3} alt="kite for marketing solutions"/>
        <img src={img2} alt="das"/>
        <img src={img1} alt="tanween creative house"/>
        <img src={img5} alt="sama style"/>
        <img src={img7} alt="safeline"/>
       
        </Carousel>
        <Item/>
        </div>
      </div>
 
    </div>
  );
}
