import React from "react";
import img1 from "../imgs/nahl group-01.png";
import img2 from "../imgs/nahl group-02.png";
import img3 from "../imgs/nahl group-03.png";
import img4 from "../imgs/nahl group-04.png";
import img5 from "../imgs/nahl group-05.png";
import img7 from "../imgs/nahl group-07.png";
import { useEffect } from "react";
export default function Companies() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="boxC2">
        <p className="pagetitle">Our Companies</p>
      </div>

      <p className="clientsslogan">Diversity is the power of balance</p>

      <div class="rowC">
        <div class="columnC">
          <div class="cardC">
            <img src={img4} className="imgC" alt="tech stations" />
            <div class="containerC">
              <h2>IT Solutions</h2>
              <p className="cardsp">
                TSC Credibility professionalism &amp; hard working were the
                reasons behind TSC success, almost 10 years of providing IT end
                to end solutions to our kingdom, proud of our partners &
                beneficiaries
              </p>

              <p>
                <a
                  class="buttonC"
                  href="https://techs.com.sa/"
                  target="_blank" rel="noopener noreferrer">
                  Visit Website
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="columnC">
          <div class="cardC">
            <img src={img3} className="imgC" alt="kite marketing solutions" />
            <div class="containerC">
              <h2>Marketing Solutions</h2>
              <p className="cardsp">
                {" "}
                A marketing agency that provides smart ideas and developing a
                strategy which allows you to fly and growing your goals. Our
                team consist of people with diverse expertise and interests,
                with a great number of artistic plans. We have a track record
                that is next to none because of our service focused approach..
              </p>

              <p>
                <a class="buttonC" href="https://www.kite-sa.com/" target="_blank"
                  rel="noopener noreferrer">
                  Visit Website
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="columnC">
          <div class="cardC">
            <img src={img1} className="imgC" alt="tanween Creative House" />
            <div class="containerC">
              <h2>Creative House</h2>
              <p className="cardsp">
                {" "}
                A creative house that is ultimately inspired by our
                collaboration with partners who value design and demand a
                competitive edge in the marketplace. Our team is a creative team
                firm with a smart mix of talent, professionalism, and marketing
                know-how. We have a tendency to produce well rounded up story in
                order to fulfill your goals
              </p>

              <p>
                <a
                  class="buttonC"
                  href="https://www.tanweensa.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Website
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="columnC">
          <div class="cardC">
            <img src={img2} className="imgC" alt="das" />
            <div class="containerC">
              <h2>Power Services</h2>
              <p className="cardsp">
                DAS Experts in Data Center Power &amp; electricity with almost
                20 years in KSA. DAS is the first UPS specialized KSA company
                with more than 400 satisfied clients.
              </p>

              <p>
                <a
                  class="buttonC"
                  href="http://www.das-sa.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Website
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="columnC">
          <div class="cardC">
            <img src={img5} className="imgC" alt="sama style Interior Design" />
            <div class="containerC">
              <h2>Interior Design</h2>
              <p className="cardsp">
                Sama Style offers interior design services for unique projects
                that satisfy our clients.With the assistance of our
                professionalism and creativity, our company has utilized all the
                possible spaces harmoniously and provided the best solutions
                within a client’s budget.
              </p>

              <p>
                <a
                  class="buttonC"
                  href="https://samastyle.sa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Website
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="columnC">
          <div class="cardC">
            <img src={img7} className="imgC" alt="safeline IT" />
            <div class="containerC">
              <h2>Cyber Security</h2>
              <p className="cardsp">
                Safeline experts in cybersecurity. Applying the Right
                Technologies, Strengthen Your Defenses. Providing Cyber Systems
                & Solutions Designed for secure Infrastructure.
              </p>

              <p>
                <a
                  class="buttonC"
                  href="https://safeline.com.sa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Website
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
