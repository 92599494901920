import React from "react";
import clc from "../imgs/clients.png";
import { useEffect } from "react";
export default function Clients() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div class="boxC">
        <p className="pagetitle">Our Clients</p>
      </div>
<p className="clientsslogan">Our client trust & satisfaction is our first goal</p>
      <p className="clientsp">NAHL holding holds multiple companies that had a great successful stories<br/> with enterprise clients, their trust is our pleasure</p>
      <img className="clc" src={clc} alt="" />
      
    </div>
  );
}
