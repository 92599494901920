import React from "react";
import emailjs from "@emailjs/browser";
import iconL from "../imgs/location.png";
import iconP from "../imgs/phone.png";
import iconE from "../imgs/email.png";
import twitter from "../imgs/twitter.png";
import linkedin from "../imgs/linkedin.png";
import maps from "../imgs/maps.png";
import l2 from "../imgs/nahl branding-01.png";
export default function Mail() {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_nn", "template_vv3ljrj", e.target, "wsD7cDRbidyMPFf_9")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const inputs = document.querySelectorAll(".input");

  function focusFunc() {
    let parent = this.parentNode;
    parent.classList.add("focus");
  }

  function blurFunc() {
    let parent = this.parentNode;
    if (this.value === "") {
      parent.classList.remove("focus");
    }
  }

  inputs.forEach((input) => {
    input.addEventListener("focus", focusFunc);
    input.addEventListener("blur", blurFunc);
  });

  return (
    // <div>
    //   <h1>Contact Us</h1>
    //   <form onSubmit={sendEmail}>

    //     <input type="text" name="name" placeholder="Your Name" required/>

    //     <label></label>
    //     <input type="text" name="user_email" placeholder="Your Email"required/>

    //     <label></label>
    //     <input type="text" name="email_subject" placeholder="Message Subject"required/>

    //     <label></label>
    //     <textarea name="message" rows='4' placeholder="Your Message"required/>
    //     <input type="submit" name="send" />
    //   </form>
    // </div>
    <div class="box5">
      <div class="container">
      <img className="logobrandingC" src={l2} alt="logo"/>
        <span class="big-circle"></span>

        <div class="form">
          <div class="contact-info">
            <h3 class="title">Get in Touch</h3>
            <p class="text">
              Interested in seeing what we can do for you? Call or email us to
              start the conversation.
            </p>

            <div class="info">
              <div class="information">
                <img src={iconP} class="icon" alt="" />
                
                <a href="tel:+966112226999"
                  className="maps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tel + 966 11 222 6 999
                </a>
              </div>
              <div class="information1">
                <p>Fax + 966 11 222 1 777</p>
              </div>
              <div class="information1">
                <p>HR Ext: 1019</p>
              </div>
              <div class="information1">
                <p>Admin Ext : 1023</p>
              </div>
              <div class="information">
                <img src={iconL} class="icon" alt="" />
                <a
                  className="maps"
                  href="https://goo.gl/maps/ifGyHSbn4kyqbiH99"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  8918 Othman Bin Affan Rd
                </a>
              </div>
              <div class="information1">
                <p>
                  <span className="loc">Al Waha Unit No 202-203</span>
                </p>
              </div>
              <div class="information1">
                <p>Additional No. 4020 Riyadh 12445 Saudi Arabia</p>
              </div>

              <div class="information">
                <img src={iconE} class="icon" alt="" />
                <a href="mailto:info@nahl.com.sa" className="maps">info@nahl.com.sa</a>
              </div>
            </div>
            <div class="social-media">
              <p>Connect with us :</p>
              <div class="social-icons">
                <a
                  href="https://twitter.com/nahlholding?lang=ar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="tt" src={twitter} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/nahl-holding/?originalSubdomain=sa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                   <img className="tt" src={linkedin} alt="" />
                
                </a> 
                <a
                  href="https://goo.gl/maps/ifGyHSbn4kyqbiH99"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="tt" src={maps} alt="" />
                </a>
              </div>
            </div>
          </div>

          <div class="contact-form">
            <span class="circle one"></span>
            <span class="circle two"></span>

            <form onSubmit={sendEmail} autocomplete="off">
              <h3 class="title">Send Us a Message</h3>
              <div class="input-container">
                <input
                  type="text"
                  name="name"
                  class="input"
                  placeholder="Name"
                  required
                />
              </div>
              <div class="input-container">
                <input
                  type="email"
                  name="user_email"
                  class="input"
                  placeholder="Email"
                  required
                />
              </div>
              <div class="input-container">
                <input
                  type="tel"
                  name="email_subject"
                  class="input"
                  placeholder="Subject"
                  required
                />
              </div>
              <div class="input-container textarea">
                <textarea
                  name="message"
                  class="input"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <input type="submit" value="Send" class="btn" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
