import React from "react";
import MailAR from "./mailAR";
import {useEffect} from "react";
export default function ContactsAR() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div class="box1AR">
        <p className="pagetitleAR">تواصل معنا</p>
        
      </div>


      <MailAR/>
    </div>
  );
}
