import React from "react";
import emailjs from "@emailjs/browser";
import iconL from "../imgs/location.png";
import iconP from "../imgs/phone.png";
import iconE from "../imgs/email.png";
import twitter from "../imgs/twitter.png";
import linkedin from "../imgs/linkedin.png";
import maps from "../imgs/maps.png";
import l2 from "../imgs/nahl branding-01.png";
export default function MailAR() {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_nn", "template_vv3ljrj", e.target, "wsD7cDRbidyMPFf_9")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const inputs = document.querySelectorAll(".input");

  function focusFunc() {
    let parent = this.parentNode;
    parent.classList.add("focus");
  }

  function blurFunc() {
    let parent = this.parentNode;
    if (this.value === "") {
      parent.classList.remove("focus");
    }
  }

  inputs.forEach((input) => {
    input.addEventListener("focus", focusFunc);
    input.addEventListener("blur", blurFunc);
  });

  return (
    // <div>
    //   <h1>Contact Us</h1>
    //   <form onSubmit={sendEmail}>

    //     <input type="text" name="name" placeholder="Your Name" required/>

    //     <label></label>
    //     <input type="text" name="user_email" placeholder="Your Email"required/>

    //     <label></label>
    //     <input type="text" name="email_subject" placeholder="Message Subject"required/>

    //     <label></label>
    //     <textarea name="message" rows='4' placeholder="Your Message"required/>
    //     <input type="submit" name="send" />
    //   </form>
    // </div>
    <div class="box5">
      <div class="container">
        <img className="logobrandingC" src={l2} alt="logo" />
        <span class="big-circle"></span>

        <div class="form">
          <div class="contact-infoAR">
            <h3 class="titleAR">معلومات التواصل</h3>
            <p class="textAR">
              هل أنت مهتم برؤية ما يمكننا القيام به من أجلك؟ اتصل بنا أو راسلنا
              عبر البريد الإلكتروني لبدء المحادثة
            </p>

            <div class="infoAR">
              <div class="informationAR">
                <img src={iconP} class="icon" alt="" />
                <a
                  href="tel:+966112226999"
                  className="maps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tel + 966 11 222 6 999
                </a>
              </div>
              <div class="information1AR">
                <p>F + 966 11 222 1 777</p>
              </div>
              <div class="information1AR">
                <p>تحويلة الموارد البشرية: 1019</p>
              </div>
              <div class="information1AR">
                <p>تحويلة المشرف: 1023</p>
              </div>
              <div class="information">
                <img src={iconL} class="icon" alt="" />
                <a
                  className="mapsAR"
                  href="https://goo.gl/maps/ifGyHSbn4kyqbiH99"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  8918 طريق عثمان بن عفان
                </a>
              </div>
              <div class="information1AR">
                <p>
                  <span className="loc">الواحة وحدة رقم 202-203</span>
                </p>
              </div>
              <div class="information1AR">
                <p>الرقم الإضافي 4020 الرياض 12445 المملكة العربية السعودية</p>
              </div>

              <div class="informationAR">
                <img src={iconE} class="icon" alt="" />
                <a href="mailto:info@nahl.com.sa" className="maps">info@nahl.com.sa</a>{" "}
              </div>
            </div>
            <div class="social-mediaAR">
              <p>:تواصل معنا على </p>
              <div class="social-icons">
                <a
                  href="https://twitter.com/nahlholding?lang=ar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="tt" src={twitter} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/nahl-holding/?originalSubdomain=sa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="tt" src={linkedin} alt="" />
                </a>
                <a
                  href="https://goo.gl/maps/ifGyHSbn4kyqbiH99"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="tt" src={maps} alt="" />
                </a>
              </div>
            </div>
          </div>

          <div class="contact-formAR">
            <span class="circle one"></span>
            <span class="circle two"></span>

            <form className="msgAR" onSubmit={sendEmail} autocomplete="off">
              <h3 class="titlemsgAR">ارسل لنا رسالة</h3>
              <div class="input-container">
                <input
                  className="inputAR"
                  type="text"
                  name="name"
                  class="input"
                  placeholder="الاسم"
                  required
                />
              </div>
              <div class="input-container">
                <input
                  className="inputAR"
                  type="email"
                  name="user_email"
                  class="input"
                  placeholder="البريد الالكتروني"
                  required
                />
              </div>
              <div class="input-container">
                <input
                  className="inputAR"
                  type="tel"
                  name="email_subject"
                  class="input"
                  placeholder="العنوان"
                  required
                />
              </div>
              <div class="input-container textarea">
                <textarea
                  className="inputAR"
                  name="message"
                  class="input"
                  placeholder="الرسالة"
                  required
                ></textarea>
              </div>
              <input type="submit" value="ارسال" class="btnAR" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
