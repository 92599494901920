import React from "react";
import img1 from "../imgs/nahl group-01.png";
import img2 from "../imgs/nahl group-02.png";
import img3 from "../imgs/nahl group-03.png";
import img4 from "../imgs/nahl group-04.png";
import img5 from "../imgs/nahl group-05.png";
import img7 from "../imgs/nahl group-07.png";
import { useEffect } from "react";
export default function CompaniesAR() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="boxC2AR">
        <p className="pagetitleAR">شركاتنا</p>
      </div>
      <p className="clientssloganAR">قوة التوازن في التنوع</p>
      <div></div>

      <div class="rowC">
        <div class="columnC">
          <div class="cardC">
            <img src={img4} className="imgC" alt="tech stations company" />
            <div class="containerC">
              <h2 className="cardspARH2">حلول تقنية المعلومات</h2>
              <p className="cardspAR">
                نوفر حلول تقنية للملكتنا العربية السعودية الحبيبة؛ بخبرة دامت
                قرابة العشر سنوات. ونراهن بأن أحد أسباب نجاحنا هي الإحترافية
                المهنية والمصداقية والمثابرة مع شركاء نجاح فخورين بهم ومستفيدين
                نسعد بخدمتهم
              </p>

              <p>
                <a
                  class="buttonCAR"
                  href="https://techs.com.sa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  زيارة الموقع
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="columnC">
          <div class="cardC">
            <img src={img3} className="imgC" alt="kite marketing solutions" />
            <div class="containerC">
              <h2 className="cardspARH2">حلول تسويقية</h2>
              <p className="cardspAR">
                {" "}
                إحدى الأبناء البارّين لمجموعة نهل القابضة أنشئت في عام ٢٠١٨
                بأيدي مجموعة خبراء تسويق هدفهم إحداث ثورة في مفهوم التسويق
                السعودي؛ طامحين نحو خلق فضاء تسويقي عالمي أكثر عمقًا وشمولًا
                يواكب ويرتقي لتطلعات عملائنا في المملكة العربية السعودية.
              </p>

              <p>
                <a class="buttonCAR" href="https://www.kite-sa.com/" target="_blank"
                  rel="noopener noreferrer">
                  زيارة الموقع
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="columnC">
          <div class="cardC">
            <img src={img1} className="imgC" alt="tanween Creative House" />
            <div class="containerC">
              <h2 className="cardspARH2">بيت ابداعي</h2>
              <p className="cardspAR">
                {" "}
                بيت إبداعي تأسس عام ٢٠١٩ وهو أحد شركات نهل القابضة، نعمل في
                المجال الإبداعي فقط لننوّن لعملائنا قصص إبداعية متكاملة من خلال
                فريق مؤهل يتمتع بالموهبة والإحترافية ومعرفة كبيرة في مجال
                التسويق. نضمن لعملائنا رحلة متفرّدة نضمن فيها جودة المخرجات.
              </p>

              <p>
                <a
                  class="buttonCAR"
                  href="https://www.tanweensa.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  زيارة الموقع
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="columnC">
          <div class="cardC">
            <img src={img2} className="imgC" alt="das" />
            <div class="containerC">
              <h2 className="cardspARH2">خدمات الطاقة</h2>
              <p className="cardspAR">
                دار السليمانية هي أول شركة متخصصة في الـ (UPS) وخبراء في مجال
                الطاقة والكهرباء ومراكز البيانات بخبرة دامت قرابة العشرين سنة في
                المملكة العربية السعودية بأكثر من ٤٠٠ عميل
              </p>

              <p>
                <a
                  class="buttonCAR"
                  href="https://www.das-sa.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  زيارة الموقع
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="columnC">
          <div class="cardC">
            <img src={img5} className="imgC" alt="sama style Interior Design" />
            <div class="containerC">
              <h2 className="cardspARH2">تصميم داخلي</h2>
              <p className="cardspAR">
                شركة متخصصة في مجال التصميم الداخلي بخبرة ٩ سنوات بعدة تجارب برز
                فيه الشغف وجودة العمل، نبتكر حلول عملية مرنة ومتقنة بحسب
                ميزانيتك ونستغل جميع المساحات استغلالًا متجانس ومستدام.
              </p>
              <p>
                <a
                  class="buttonCAR"
                  href="https://samastyle.sa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  زيارة الموقع
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="columnC">
          <div class="cardC">
            <img src={img7} className="imgC" alt="safeline IT" />
            <div class="containerC">
              <h2 className="cardspARH2">الأمن السيبراني</h2>
              <p className="cardspAR">
                خبراء بمجال آمن المعلومات بتنفيذ جميع التقنيات الصحيحة لحماية
                وتقوية نظامك والدفاع عنه؛ نوفر حلول وأنظمة تقنية مخصصة لك.
              </p>

              <p>
                <a
                  class="buttonCAR"
                  href="https://safeline.com.sa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  زيارة الموقع
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
