import React from "react";
import Mail from "./mail";
import { useEffect } from "react";
export default function Contacts() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div class="box1">
        <p className="pagetitle">Contact Us</p>
      </div>

      <Mail />
    </div>
  );
}
