import React from "react";
import p from "../imgs/planning.svg";
import v from "../imgs/vision.svg";
import q from "../imgs/quality.svg";
import m from "../imgs/manage.svg";
import d from "../imgs/diversify.svg";
import l from "../imgs/nahl white-03.png";
import img1 from "../imgs/nahl group-01.png";
import img2 from "../imgs/nahl group-02.png";
import img3 from "../imgs/nahl group-03.png";
import img4 from "../imgs/nahl group-04.png";
import img5 from "../imgs/nahl group-05.png";
import img7 from "../imgs/nahl group-07.png";
import Carousel from 'react-elastic-carousel';
export default function HomeAR() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
  ];
  return (
    <div>
      <div className="box2">
        
      <img className="logoindexAR" src={l} alt="logo"/>
        <p className="ppAR">
          <span className="titleWordAR">نهل القابضة</span>
          .نهل هي شركة سعودية قابضة تمتلك شركات مختلفة في عدة مجالات، تركز على أعمال المستقبل{" "}
          <br />
          <br /> <br /> <br />
        </p>
        <h1 className="sloganAR">
          
          "يقودنا الابتكار والتميز" 
        </h1>
      </div>
      <div className="box3">
        <div>
          <p className="pvisionAR">
            <img src={v} class="visionico" alt="" />
            <span className="visiontitleAR">رؤيتنا</span>
            رؤية نهل هي أن تصبح شركة معروفة عالمياً قائمة على إضافة قيم حقيقية لوطننا <br />
            الغالي والجيل القادم، نشارك في صناعة مستقبل أفضل، عن طريق خطوات مدروسة
            <br />
            .وبعد تحليل عميق وتخطيط دقيق بواسطة فريقنا الاحترافي
            <br />
            <br />
            <br />
            <br />
          </p>
        </div>
        <div className="visionsGroup">
          <p className="visionsAR">
            <img src={q} class="qualityicon" alt="" />
            <span className="visionstitleAR">ضمان الجودة</span>
            ،بالاطلاع على كافة العمليات وإجراءات العمل، جمع انطباع العملاء
            <br />
            اتباع أفضل الممارسات في ضمان الجودة، نضمن 
            <br />
            .جودة أعمال الشركات المملوكة لنهل
          </p>
          <p className="visionsAR">
            <img src={p} class="visionicon" alt="" />
            <span className="visionstitleAR">التخطيط</span>
            تخطيط قصير وبعيد المدى في غاية التنظيم لتقليص المخاطر 
            <br />
            ،لضمان الاستغلال الأعلى لقدرات الشركات
            <br />
            .وتحويل الظروف والأزمات إلى منافع
          </p>
          <p className="visionsAR">
            <img src={m} class="visionicon" alt="" />
            <span className="visionstitleAR">الإدارة والتحكم</span>
            عن طريق فريق عمل نهل: نقوم بتقديم خدمات الإدارة
            <br />
            ،لكافة الشركات المملوكة من نهل: الإدارة المالية، الموارد البشرية
            <br />
            .لخدمات اللوجستية، والأعمال الإدارية
          </p>
          <p className="visionsAR">
            <img src={d} class="visionicon" alt="" />
            <span className="visionstitleAR">التنوع</span>
            ،لزيادة التوازن لنهل القابضة بالبحث دائماً عن فرص استثمارية جديدة
            <br />
            ،سواءً كان إنشاء خدمات جديدة، إنشاء شركات جديدة
            <br />
            .أو الاستحواذ على شركات قائمة. فريقنا الاستشاري دائماً على استعداد
          </p>
        </div>
      </div>
      <div className="box4">
        <h1 className="comAR">شركاتنا</h1>
        <div className="carousel-wrapper">
        <Carousel className="item" breakPoints={breakPoints}>
        <img src={img4} alt="tech stations company"/>
        <img src={img3} alt="kite for marketing solutions"/>
        <img src={img2} alt="das"/>
        <img src={img1} alt="tanween creative house"/>
        <img src={img5} alt="sama style"/>
        <img src={img7} alt="safeline"/>
       
        </Carousel>
        </div>
      </div>
    </div>
  );
}
